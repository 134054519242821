import React from 'react';
import ReactDOM from 'react-dom';
// import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Typography, Box, CircularProgress } from '@mui/material';
import { Backdrop } from '@mui/material';
import { useSelector } from 'react-redux';
import RootState from '@src/interfaces/RootState';
const LoadingScreen = () => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const isLoading = useSelector((state: RootState) => state.ui.isLoading);

    /* FUNCTIONS */
    const content = (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress color="inherit" sx={{ margin: '1rem auto' }} />
                <Typography align="center" className="loading__title">
                    {t('common.please_wait')}
                </Typography>
            </Box>
        </Backdrop>
        // <div className="loading">
        //     <Box className="loading-spinner__wrapper">
        //         <img className="loading-spinner__icon" src="/images/spinner.svg" alt="Loader" />
        //     </Box>
        //     <Typography align="center" className="loading__title">
        //         {t('common.please_wait')}
        //     </Typography>
        // </div>
    );

    /* USEEFFECTS */

    return ReactDOM.createPortal(content, document.getElementById('loader-hook')!);
};

export default LoadingScreen;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(index)
