class AddressAutocompleteModel {
    streetNo: string;
    street: string;
    country: string;
    countryCode: string;
    city: string;
    postalCode: string;
    change: (key: string, value: any) => void;
    form?: string;

    constructor(data, change, formName) {
        this.streetNo = data.streetNo;
        this.street = data.street;
        this.country = data.country;
        this.countryCode = data.countryCode;
        this.city = data.city;
        this.postalCode = data.postalCode;
        this.change = change;
        this.form = formName;
    }

    insertToRedux = () => {
        for (const item in this) {
            if (typeof this[item] === 'string' && item !== 'form') {
                this.change(item, this[item]);
                console.log(item);
            }
        }
    };
}

export default AddressAutocompleteModel;
