import { Button } from '@MyComponents';
import React, { HtmlHTMLAttributes } from 'react';
// import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

interface ButtonGroup {
    handleSteps: (value: HTMLButtonElement) => void;
    previous?: boolean;
}

const ButtonGroup = ({ previous, handleSteps }: ButtonGroup) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');

    /* FUNCTIONS */

    /* USEEFFECTS */

    return (
        <div className="button-group">
            <Button buttonType="button" level="primary" variant="contained" name="previous" onClick={(e: HTMLButtonElement | any) => handleSteps(e.target.name)}>
                {t('common.previous')}
            </Button>
            <Button buttonType="button" level="primary" variant="contained" name="next" onClick={(e: HTMLButtonElement | any) => handleSteps(e.target.name)}>
                {t('common.next')}
            </Button>
        </div>
    );
};

export default ButtonGroup;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(ButtonGroup)
