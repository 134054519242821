import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const renderCheckboxWithImage = ({ input, image, label }) => {
    return (
        <div className={`checkbox__with-image ${input.checked ? 'checked' : ''}`}>
            <FormControlLabel
                control={
                    <div className="checkbox__with-image_wrapper">
                        <img className="checkbox__image" src={image} />
                        <Checkbox checked={input.value ? true : false} onChange={input.onChange} />
                        <span className="checkbox__with-image_label">{label}</span>
                    </div>
                }
                label=""
            />
        </div>
    );
};

export default renderCheckboxWithImage;
