// import { reduxForm } from 'redux-form';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';
import { LoadingScreen } from '@MyComponents';
import Footer from './footer';
import ResponsiveAppBar from './navigation/index';

const Layout = ({ children }) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');

    /* FUNCTIONS */

    /* USEEFFECTS */

    return (
        <div className="layout">
            <ResponsiveAppBar />
            <Container maxWidth="lg" sx={{ padding: '1rem' }}>
                <LoadingScreen />
                {children}
            </Container>
            {/* <Footer /> */}
        </div>
    );
};

export default Layout;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(index)
