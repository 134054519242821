import * as actionTypes from './actions';

const initialState = {
    page: 1,
    maxPages: 6, // Total pages of the journey - neccessary for the Wizard handling
    isLoading: false,
    language: '',
    loggedIn: false,
    customerData: {},
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_MAX_PAGES:
            return {
                ...state,
                maxPages: action.val,
            };
        case actionTypes.SET_PAGE:
            return {
                ...state,
                page: action.val,
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.val,
            };
        case actionTypes.LOGGED_IN:
            return {
                ...state,
                loggedIn: action.val,
            };
        case actionTypes.LOAD_CUSTOMERDATA:
            return {
                ...state,
                customerData: action.val,
            };
        default:
            return state;
    }
}
