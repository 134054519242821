import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
    palette: {
        primary: {
            main: '#653d32',
        },
        secondary: {
            main: '#CE9C08',
        },
    },
});
