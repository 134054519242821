import { reduxForm, Field } from 'redux-form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Typography } from '@mui/material';
import * as MyComponents from '@MyComponents';
import { required } from 'redux-form-validators';
// import renderCheckboxWithImage from '@src/components/form/CheckBoxWithImage';
// import renderCheckbox from '@src/components/form/Checkbox';
import img from '@img/imagePng.png';
import { useSelector } from 'react-redux';
import RootState from '@src/interfaces/RootState';
import Address from '../../components/addressForm/index';
const genders = ['female', 'male', 'other'];
const genderWImage = [
    { name: 'female', image: 'http://localhost:3001/821ae1d0e56384a61252.png' },
    { name: 'male', image: 'http://localhost:3001/821ae1d0e56384a61252.png' },
    { name: 'other', image: 'http://localhost:3001/821ae1d0e56384a61252.png' },
];

const ComponentPage = (props) => {
    /* STATES */
    const [selectedItem, setSelectedItem] = useState();

    /* VARIABLES */
    const { t } = useTranslation('common');
    const selectedRadio = useSelector((state: RootState) => state?.form?.componentpage?.values?.genders);

    /* FUNCTIONS */

    /* API FUNCTIONS */

    /* USEEFFECTS */
    useEffect(() => {
        setSelectedItem(selectedRadio);
    }, [selectedRadio]);

    return (
        <Container sx={{ margin: '2rem 1rem' }}>
            {/* Autocomplete Address*/}
            <Address props={props} />

            {/* Text input */}
            <Box sx={{ margin: '2rem' }}>
                <Typography variant="h6">Text fields</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Field
                            name="username"
                            component={MyComponents.renderTextField}
                            type="text"
                            variant="filled"
                            validate={[required({ msg: t('validations.required') })]}
                            label={t('login.username')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name="username2"
                            component={MyComponents.renderTextField}
                            type="text"
                            variant="standard"
                            validate={[required({ msg: t('validations.required') })]}
                            label={t('login.password')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name="username3"
                            component={MyComponents.renderTextField}
                            type="text"
                            variant="outlined"
                            validate={[required({ msg: t('validations.required') })]}
                            label={t('login.login')}
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* Checkbox */}
            <Box sx={{ margin: '2rem' }}>
                <Typography variant="h6">Checkbox</Typography>
                <Field name="employee1" component={MyComponents.renderCheckbox} label="employee 1" />
                <Field name="employee2" component={MyComponents.renderCheckbox} label="employee 2" />
            </Box>

            {/* Checkbox with image */}
            <Box sx={{ margin: '2rem' }}>
                <Typography variant="h6">Checkboxes with images</Typography>
                <Box sx={{ display: 'flex' }}>
                    <Field name="damage1" label="Damage type1" component={MyComponents.renderCheckboxWithImage} type="checkbox" image={img} validate={[required({ msg: t('validations.required') })]} />
                    <Field name="damage2" label="Damage type2" component={MyComponents.renderCheckboxWithImage} type="checkbox" image={img} validate={[required({ msg: t('validations.required') })]} />
                </Box>
            </Box>
            {/* Radio buttons */}
            <Box>
                <Typography variant="h6">Radio buttons</Typography>
                <Field name="gender" radioOptions={genders} component={MyComponents.renderRadioButton} label="gender" />
            </Box>

            {/* Radio buttons with image*/}
            <Box>
                <Typography variant="h6">Radio buttons with images</Typography>
                <Field name="genders" radioOptions={genderWImage} selectedRadio={selectedItem} component={MyComponents.renderRadioButtonWithImage} label="GenderWImage" />
            </Box>
        </Container>
    );
};

// export default ComponentPage;

export default reduxForm({
    form: 'componentpage',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnBlur: true,
})(ComponentPage);
