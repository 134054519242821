import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link, useHistory } from 'react-router-dom';
import cookie from 'react-cookies';
import { useContext, useEffect } from 'react';
import { AuthContext } from '@src/contexts/auth/authContext';
import { useTranslation } from 'react-i18next';
import theme from '@theme';
import { Button } from '@MyComponents';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '@src/interfaces/RootState';
// const pages = ['Home', 'New Claim', 'Claim History', 'Contact'];

const pagess = [
    // { page: 'Home', url: '/' },
    { page: 'Dashboard', url: 'dashboard' },
    // { page: 'Components', url: 'components' },
    // { page: 'Claim', url: 'claim' },
    // { page: 'Claim History', url: 'claim-history' },
    { page: 'Contact', url: 'contact' },
];
const settings = ['Profile', 'Logout'];

const ResponsiveAppBar = () => {
    /* STATES */
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    /* VARIABLES */
    const history = useHistory();
    console.log('file: index.tsx ~ line 42 ~ ResponsiveAppBar ~ history', history);

    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();

    const {
        authState: { isAuthenticated },
    } = useContext<any>(AuthContext);
    const { authDispatch } = useContext<any>(AuthContext);
    // const isLoggedIn = useSelector((state: RootState) => state?.ui?.isLoggedIn);

    /* FUNCTIONS */
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        cookie.remove('token');
        localStorage.clear();
        sessionStorage.clear();
        dispatchStore({ type: 'LOGGED_IN', val: false });
        authDispatch({ type: 'SIGNED_OUT' });
        history.push('/login');
    };

    /* USEEFFECTS */
    // Every render check if cookie exists, if so, run dispatch
    useEffect(() => {
        if (sessionStorage.getItem('token') !== null) {
            authDispatch({ type: 'SIGNIN_SUCCESS' });
        }
    }, []);
    return (
        <AppBar className="navigation" position="static" sx={{ backgroundColor: theme.secondaryColor }}>
            <Container maxWidth="lg">
                <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex' }}>
                        {/* PROJECT LOGO */}
                        <img src="/images/Ferrero_logo.svg" className="navigation-logo" />
                        {/* PROJECT NAME IF NECESSARY */}
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/dashboard"
                            sx={{
                                justifySelf: 'start',
                                mr: 2,
                                ml: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: '#653D32',
                                textDecoration: 'none',
                                textTransform: 'uppercase',
                            }}
                        >
                            Insurance
                            {/* {process.env.PROJECT_NAME} */}
                        </Typography>
                    </Box>

                    {/* HAMBURGER MENU ICON + LIST */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'end' }}>
                        <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                // position: 'absolute',
                                top: '0',
                                right: '0',
                            }}
                        >
                            <Link to="/login">
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{t('navigation.login')}</Typography>
                                </MenuItem>
                            </Link>
                            {pagess.map((page) => {
                                return (
                                    <Button key={`xs-${page.page}`} buttonType={'button'}>
                                        <Link key={`xs-${page.page}`} to={page.url}>
                                            <MenuItem onClick={handleCloseNavMenu}>
                                                <Typography textAlign="center">{page.page}</Typography>
                                            </MenuItem>
                                        </Link>
                                    </Button>
                                );
                            })}
                        </Menu>
                    </Box>

                    {/* DESKTOP MENU ITEMS */}
                    {isAuthenticated === true && (
                        <Box sx={{ flexGrow: 1, padding: '0 1.5rem', display: { xs: 'none', md: 'flex' } }}>
                            {pagess.map((page) => (
                                <Link key={`md-${page.page}`} to={page.url}>
                                    <Button buttonType="button" level="tertiary" onClick={handleCloseNavMenu}>
                                        {page.page}
                                    </Button>
                                </Link>
                            ))}
                        </Box>
                    )}

                    {/* DESKTOP PROFILE MENU */}
                    <Box sx={{ flexGrow: 0 }}>
                        {isAuthenticated === true ? (
                            <>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {/* {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))} */}
                                    <MenuItem>
                                        <Typography textAlign="center">Menu</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout}>
                                        <Typography textAlign="center">{t('navigation.logout')}</Typography>
                                    </MenuItem>
                                </Menu>
                            </>
                        ) : (
                            ''
                        )}
                        {history.location.pathname === '/login' && (
                            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Link to="/login">{t('navigation.login')}</Link>
                            </Box>
                        )}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
