import { reduxForm } from 'redux-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ClaimWizard from '@src/components/ui/ClaimWizard';
import RootState from '@src/interfaces/RootState';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup } from '@MyComponents';

const NewClaim = () => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const dispatchAction = useDispatch();
    const currentPage = useSelector((state: RootState) => state.ui?.page);
    const maxPages = useSelector((state: RootState) => state.ui?.maxPages);

    /* FUNCTIONS */
    const handleSteps = (step) => {
        if (step === 'previous' && currentPage > 1) {
            dispatchAction({ type: 'SET_PAGE', val: currentPage - 1 });
        }
        if (step === 'next' && currentPage < maxPages) {
            dispatchAction({ type: 'SET_PAGE', val: currentPage + 1 });
        }
    };
    /* API FUNCTIONS */

    /* USEEFFECTS */

    return (
        <div className="claimjourney">
            <ClaimWizard page={currentPage} maxPages={maxPages} />
            <ButtonGroup handleSteps={handleSteps} />
        </div>
    );
};

// export default NewClaim;

export default reduxForm({
    form: 'claimform',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnBlur: false,
})(NewClaim);
