import { reduxForm } from 'redux-form';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
// https://www.npmjs.com/package/react-places-autocomplete
import renderAddressAutoComplete from '../form/AddressAutocomplete';
import { Box, Button, Grid, Typography } from '@mui/material';
import * as MyComponents from '@MyComponents';
import { required } from 'redux-form-validators';
const Address = (props) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    /* FUNCTIONS */
    const setAddressToStore = (value) => {
        value.insertToRedux();
    };
    /* API FUNCTIONS */

    /* USEEFFECTS */

    return (
        <Box sx={{ margin: '2rem' }}>
            <Typography variant="h6">Address with autocomplete</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginBottom: '2rem' }}>
                    <Field formName={props.form} change={props.change} setAddressToStore={setAddressToStore} name="address" type="text" component={renderAddressAutoComplete} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <Field formName={props.form} change={props.change} setAddressToStore={setAddressToStore} name="address" type="text" component={renderAddressAutoComplete} />
                </Grid> */}
                <Grid item xs={3}>
                    <Field name="city" component={MyComponents.renderTextField} type="text" variant="filled" validate={[required({ msg: t('validations.required') })]} label={t('customerdata.city')} />
                </Grid>
                <Grid item xs={3}>
                    <Field
                        name="country"
                        component={MyComponents.renderTextField}
                        type="text"
                        variant="standard"
                        validate={[required({ msg: t('validations.required') })]}
                        label={t('customerdata.country')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Field
                        name="postalCode"
                        component={MyComponents.renderTextField}
                        type="text"
                        variant="outlined"
                        validate={[required({ msg: t('validations.required') })]}
                        label={t('customerdata.post_code')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Field
                        name="street"
                        component={MyComponents.renderTextField}
                        type="text"
                        variant="outlined"
                        validate={[required({ msg: t('validations.required') })]}
                        label={t('customerdata.street')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Field
                        name="streetNo"
                        component={MyComponents.renderTextField}
                        type="text"
                        variant="outlined"
                        validate={[required({ msg: t('validations.required') })]}
                        label={t('customerdata.street_num')}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

// export default Address;

export default reduxForm({
    form: 'addressForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnBlur: false,
})(Address);
