import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import TextField from '@mui/material/TextField';
import { reduxForm, Field } from 'redux-form';
import { renderTextField } from '@MyComponents';
import AddressAutocompleteModel from '@src/models/AddressAutocompleteModel';

const renderAddressAutoComplete = ({ formName, setAddressToStore, change, onChange, name, label, input, variant, meta: { touched, invalid, error, visited }, ...custom }) => {
    const handleChange = (place) => {
        const addressInput = {
            streetNo: place?.address_components?.find((addressItem) => addressItem.types.includes('street_number'))?.short_name,
            street: place?.address_components?.find((addressItem) => addressItem.types.includes('route'))?.long_name,
            country: place?.address_components?.find((addressItem) => addressItem.types.includes('country'))?.long_name,
            countryCode: place?.address_components?.find((addressItem) => addressItem.types.includes('country'))?.short_name,
            city: place?.address_components?.find((addressItem) => addressItem.types.includes('locality'))?.short_name,
            postalCode: place?.address_components?.find((addressItem) => addressItem.types.includes('postal_code'))?.short_name,
        };
        const AddressModel = new AddressAutocompleteModel(addressInput, change, formName);
        setAddressToStore(AddressModel);
    };
    return (
        <>
            <Autocomplete
                apiKey={'AIzaSyCx9tHdp8iZPZbBNhQMO1CGDGtpSvcpD8g'}
                onPlaceSelected={(place) => {
                    // console.log('file: AddressAutocomplete.tsx ~ line 30 ~ renderAddressAutoComplete ~ place', place);
                    handleChange(place);
                }}
                options={{
                    types: ['geocode', 'establishment'],
                }}
            />
        </>
    );
};

export default renderAddressAutoComplete;
