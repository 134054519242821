import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosRequestConfiguration } from './config';
import cookie from 'react-cookies';

const tokenFunction = () => {
    const token = sessionStorage.getItem('token');
    return `Bearer ${token}`;
};

const initialization = (configs: AxiosRequestConfig): AxiosInstance => {
    const axiosInstance = axios.create(configs);

    axiosInstance.interceptors.request.use((request) => {
        if (request.url?.includes('claim/list')) {
            request.headers.Authorization = tokenFunction();
            return request;
        }
    });
    return axiosInstance;
};

const axiosInstance = initialization(axiosRequestConfiguration);

export default axiosInstance;
