import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// Pass an Array of strings with the radio options (as radioOptions)
// e.g.: ["female", "male", other]

const renderRadioButton = ({ input, radioOptions, ...rest }) => (
    <div className="radio-button">
        <FormControl>
            <div className="radio-button__group_wrapper">
                <RadioGroup {...input} {...rest}>
                    {radioOptions.map((item, index) => {
                        return <FormControlLabel key={item + index} value={item} control={<Radio />} label={item} />;
                    })}
                </RadioGroup>
            </div>
        </FormControl>
    </div>
);

export default renderRadioButton;
