import AuthService from '@src/api/services/authService';
import { put, select } from 'redux-saga/effects';
import { SET_LOADING } from '../ui/actions';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export function* refreshToken() {
    yield put({ type: SET_LOADING, val: true });
    // Get request for TOKEN
    const response = yield AuthService.tokenRefresh();
    yield put({ type: SET_LOADING, val: false });
}
