import React from 'react';
import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// 1. Pass an Array of object with the radio options (as radioOptions)
//      e.g.: ["female", "male", other]
// 2. Pass the selected radio element to set the checked class for highlighting (as selectedRadio)

const RenderRadioButtonWithImage = ({ input, label, radioOptions, selectedRadio, ...rest }) => {
    return (
        <div className="radio-button__with-image_group">
            <FormControl>
                <RadioGroup {...input} {...rest} sx={{ display: 'flex', flexDirection: 'row' }}>
                    {radioOptions.map((item, index) => {
                        return (
                            <div key={item.name + index} className="radio-button__group_wrapper">
                                <Box className={`${selectedRadio === item.name ? 'checked' : ''} radio-button__with-image_wrapper`} sx={{ margin: '0 1rem' }}>
                                    <img
                                        className="radio-button__image"
                                        src={item.image}
                                        onClick={() => {
                                            input.onChange(item.name);
                                        }}
                                    />
                                    <span className="radio-button__with-image_label">{item.name}</span>
                                    {/* <FormControlLabel key={item.name + index} value={item.name} control={<Radio />} label={item.name}></FormControlLabel> */}
                                </Box>
                            </div>
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default RenderRadioButtonWithImage;
