// import { reduxForm } from 'redux-form';
import { handleGeneralError } from '@src/api/errorHandling/ErrorHandling';
import { GeneralErrors } from '@src/api/models/Error';
import RootState from '@src/interfaces/RootState';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setNotification } from '../ui/Notifications';

const MainWrapper = ({ children }) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const error = useSelector((state: RootState) => state?.error);
    /* FUNCTIONS */

    /* API FUNCTIONS */

    /* USEEFFECTS */
    useEffect(() => {
        if (error.errorObject !== null) {
            const target = t(`${error?.errorObject?.dictionaryObject}.${handleGeneralError(GeneralErrors, error?.errorObject?.errorCode)}`);

            setNotification(t('error_handling.warning'), target, 'warning');
        }
    }, [error]);

    return <main className="main-wrapper">{children}</main>;
};

export default MainWrapper;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(index)
