import React from 'react';
import Layout from '@src/components/layout/Layout';
import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '@src/contexts/auth/protectedRoute';
import ComponentPage from '@src/pages/componentPage';
import Claim from '@src/pages/claim';
// import TravelContractSuccess from '@src/pages/travel/contract/components/success';

const TravelContract = lazy(() => import('@src/pages/travel/contract'));
const NotFound404 = lazy(() => import('@src/pages/notFound'));
const Contact = lazy(() => import('@src/pages/contact/index'));
const Login = lazy(() => import('@src/pages/login/index'));
const Home = lazy(() => import('@src/pages/index'));
const Dashboard = lazy(() => import('@src/pages/dashboard'));
const Travel = lazy(() => import('@src/pages/travel'));
const TravelClaim = lazy(() => import('@src/pages/travel/claim'));
const Success = lazy(() => import('@src/pages/travel/contract/components/success'));
const TravelContractSuccess = lazy(() => import('@src/pages/travel/contract/components/success'));
const TermsAndConditions = lazy(() => import('@src/pages/travel/claim/components/termsAndConditions'));

const Router = () => {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route exact path="/">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Login />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/login">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Login />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/dashboard">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Dashboard />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/travel">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Travel />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/travel/termsAndConditions">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <TermsAndConditions />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/travel/contract">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <TravelContract />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/travel/claim">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <TravelClaim />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/travel/contract/success">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <TravelContractSuccess />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/travel/claim/success">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Success />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/components">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <ComponentPage />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/claim">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Claim />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/contact">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Contact />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route path="*">
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <NotFound404 />
                            </Suspense>
                        </Layout>
                    </Route>
                </Switch>
            </Suspense>
        </>
    );
};

export default Router;
