import axiosInstance from '../http/axiosSetup';
import cookie from 'react-cookies';
import { LoginResponse, LoginPayload } from '../interfaces/Login';

export default class AuthService {
    static performLogin = (body: LoginPayload) =>
        axiosInstance
            .post('/login', body)
            .then((res) => {
                console.log('file: authService.ts ~ line 10 ~ AuthService ~ .then ~ res', res);

                res.data.status = res.status;
                res.data.token = res.headers?.authorization;
                return res;
            })
            .catch((err) => {
                return err;
            });

    static tokenRefresh = () => {
        axiosInstance
            .get('refresh/token')
            .then((res) => {
                const token = res?.headers?.authorization;
                sessionStorage.setItem('token', token);
                return token;
            })
            .catch((err) => {
                return err.response;
            });
    };
}
