import React from 'react';

/** INFORMATION
 * Wizard based on stored 'maxPages' value in redux store.
 * We handle the page number from there, the only TODO is
 * to make a decision about how many steps we are going to
 * handle, and just rewrite the 'maxPages' value
 */
interface ClaimWizardProps {
    page: number;
    maxPages: number;
}
const ClaimWizard: React.FC<ClaimWizardProps> = ({ page, maxPages }) => {
    const Wizard = () => {
        const wizardSteps: Array<any> = [];
        for (let i = 1; i <= maxPages; i++) {
            wizardSteps.push(
                <div key={`step-${i}`} className={`wizard__step_wrapper ${page >= i && 'active'}`}>
                    <div className={`wizard__step-container ${page >= i && 'active'}`}>{i}</div>
                </div>
            );
        }
        return <>{wizardSteps}</>;
    };
    return (
        <div className="wizard">
            <div className={`wizard__steps w-50 m-auto'}`}>
                <Wizard />
            </div>
        </div>
    );
};

export default ClaimWizard;
